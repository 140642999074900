type InfoIconProps = {
    text: string;
};

function InfoIconComponent({ text }: Readonly<InfoIconProps>) {
    if (!text) return null;

    return (
        <span className="dws-info-icon" title={text} />
    );
};

export { InfoIconComponent };