import { useMemo } from "react";
import { TabWrapper, Tab } from "@dws/dws-styleguide";
import { SafeParcel } from "../../components/SafeParcel/SafeParcel";
import { WidgetLayoutType } from "../../types/WidgetTypes";
import { useTranslation } from "@dws/dws-profile";
import { InfoIconComponent } from "../Info/InfoIconComponent";

type BottomListsWrapperProps = {
  widgetLayout: WidgetLayoutType;
};

function generateInfoText(widgetLayout: WidgetLayoutType, translate: any) {
    let infoText = '';
    if (widgetLayout.includes("actions_list")) {
        infoText += translate("reports.actions_list_info") + "\n";
    };
    if (widgetLayout.includes("individual_routines_list")) {
        infoText += translate("reports.individual_routines_list_info") + "\n";
    };
    if (widgetLayout.includes("collaborative_routines_list")) {
        infoText += translate("reports.collaborative_routines_list_info") + "\n";
    };
    if (widgetLayout.includes("fivewhy_list")) {
        infoText += translate("reports.fivewhy_list_info");
    };
    return infoText;
}

export function BottomListsWrapper({
  widgetLayout,
}: Readonly<BottomListsWrapperProps>) {
    const { t } = useTranslation();

    const infoTextMemo = useMemo(() => {
        const infoText = generateInfoText(widgetLayout, t);
        return infoText;
    }, [widgetLayout]);

    if (!widgetLayout || widgetLayout.length === 0) return null;

  return (
    <>
        <TabWrapper>
        {widgetLayout.includes("actions_list") ? (
            <Tab title={t("actions.actions")} name="actions">
            <SafeParcel parcelPath="@dws/dws-reports" name="dws-actions-list" />
            </Tab>
        ) : null}
        {widgetLayout.includes("individual_routines_list") ? (
            <Tab title={t("reports.individual_routines")} name="individual-routines">
            <SafeParcel
                parcelPath="@dws/dws-reports"
                name="dws-individual-routines-list"
            />
            </Tab>
        ) : null}
        {widgetLayout.includes("collaborative_routines_list") ? (
            <Tab title={t("reports.collaborative_routines")} name="collaborative-routines">
            <SafeParcel
                parcelPath="@dws/dws-reports"
                name="dws-collaborative-routines-list"
            />
            </Tab>
        ) : null}
        {widgetLayout.includes("fivewhy_list") ? (
            <Tab title="5Whys" name="5whys">
            <SafeParcel parcelPath="@dws/dws-reports" name="dws-fivewhy-list" />
            </Tab>
        ) : null}
        </TabWrapper>
        <InfoIconComponent text={infoTextMemo} />
    </>
  );
}
