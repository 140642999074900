import { useEffect } from "react";
import { CustomEventBus, CustomEventBusSchema } from "../types/CustomEventBus";
import { CustomEventType } from "../types/CustomEventType";

document["previousCalls"] ||= {
  [CustomEventType.BUTTON_CLICKED]: null,
  [CustomEventType.CHART_DATA]: null,
  [CustomEventType.NAVIGATE]: null,
  [CustomEventType.COUNT_CHANGED]: null,
  [CustomEventType.LOAD]: null,
  [CustomEventType.ERROR]: null,
  [CustomEventType.REQUEST_REFRESH]: null,
  [CustomEventType.FILTERS_CHANGED]: null,
};

/**
 * Usage:
 *  ******Listening to an event: **********
 * import { useCustomEventBusListener } from 'hooks/useEventBus';
 *
 * useCustomEventBusListener("BUTTON_CLICKED", (event) => {
 *  console.log(event.payload.buttonId);
 * });
 **/
export function useCustomEventBusListener(
  eventType: CustomEventBus["type"],
  callback: (event: CustomEventBus) => void
) {
  useEffect(() => {
    const eventListener = (event: CustomEvent) => {
      callback(event.detail);
    };
    // If the event was dispatched before the listener was added, call the listener immediately
    if (document["previousCalls"][eventType]) {
      callback(document["previousCalls"][eventType] as CustomEventBus);
    }
    self.addEventListener(eventType, eventListener);
    return () => self.removeEventListener(eventType, eventListener);
  }, [eventType]);
}

export function useEventBusLastCall(eventType: CustomEventBus["type"]) {
  return document["previousCalls"][eventType];
}

/**
  * Usage:
  *  *****Dispatching an event: **********
  * import { dispatchCustomEvent } from 'hooks/useEventBus';
  * 
  * export const ButtonComponent: React.FC<ButtonComponentProps> = ({ buttonId }) => {
  *  const handleClick = () => {
  *   dispatchCustomEvent({ type: "BUTTON_CLICKED", payload: { buttonId } });
  * };

  * return <button onClick={handleClick}>Click me!</button>;
 **/
export function dispatchCustomEvent(event: CustomEventBus) {
  CustomEventBusSchema.parse(event); // Validates the event
  self.dispatchEvent(new CustomEvent(event.type, { detail: event }));
  // stores the event in the browser's event cache, so if the event is dispatched before the listener is added, the listener will still be called
  document["previousCalls"][event.type] = event;
}

export function cleanupPreviousCalls() {
  Object.keys(document["previousCalls"]).forEach((eventType) => {
    document["previousCalls"][eventType] = null;
  });
}
