import { SafeParcel } from "./components/SafeParcel/SafeParcel";
import { sizes } from "./grid-templates/AreaGrid";
import React, { useEffect } from "react";
import "./root-component.css";
import { useFilters } from "./hooks/useFilters";
import { useUserApi, useTranslation } from "@dws/dws-profile";
import { saveUser } from "@dws/dws-auth";
import { ShortcutsWidget } from "@dws/dws-shortcuts";
import { useWidgetData } from "./hooks/useWidgetData";

const initialLocationData = { widgets: [] };

export function Root({ authenticationData }: Readonly<{ authenticationData? }>) {
  const main = document.getElementById(
    "single-spa-application:@dws/dws-main-page-scaffold"
  );
  const width = main.clientWidth;
  const filters = useFilters();
  const { t, i18n } = useTranslation();
  const { data: widgets, isLoading } = useWidgetData(
    filters,
    authenticationData
  );

  const formatWidgetData = (widgetsData) => {
    if (!Array.isArray(widgetsData)) return initialLocationData;
    return {
      ...widgetsData,
      locationName: filters.location.locationName,
      widgets: widgetsData.map((widget) =>
        widget.componentName.toLowerCase().replace(/ /g, "_")
      ),
    };
  };

  const pageStyle: React.CSSProperties = {
    maxWidth: "1600px",
    margin: "0 auto",
    display: "grid",
    gap: "1rem",
  };

  const key = width > 1200 ? "desktop" : width > 768 ? "tablet" : "smartphone";
  const Grid = sizes[key];

  const location = isLoading ? initialLocationData : formatWidgetData(widgets);

  const getUserData = async () => {
    const response = await useUserApi();
    const user = response?.data || {};
    const language = user?.userConfiguration?.language || "en";
    saveUser(user);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div style={pageStyle}>
      <ShortcutsWidget />
      {location.widgets.includes("all") ||
      location.widgets.includes("shift_handover") ? (
        <SafeParcel
          parcelPath="@dws/dws-shift-handover-notes"
          name="dws-shift-handover-notes"
        />
      ) : null}
      <SafeParcel parcelPath="@dws/dws-filters" name="dws-filters" />
      {Grid && <Grid location={location} />}
      {isLoading ? (
        <div className="error-message">{t("general.loading")}</div>
      ) : null}
      {location.widgets.length === 0 && !isLoading ? (
        <div className="error-message">{t("general.location_error")}</div>
      ) : null}
    </div>
  );
}
