// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dws-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
}

.dws-row {
  display: grid;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/grid-templates/grid-definition.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,SAAS;AACX","sourcesContent":[".dws-grid {\n  display: grid;\n  gap: 1rem;\n  grid-template-columns: 1fr;\n}\n\n.dws-row {\n  display: grid;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
