import { useQuery } from "@tanstack/react-query";
import { FetchHTTPClient } from "../components/shared/httpClient/impl/fetchHttpClient";
import { IHttpClient } from "../components/shared/httpClient/abstractHttpClient";
import { config } from "@dws/dws-auth";

export function useWidgetData(filters, authenticationData) {
  return useQuery({
    queryKey: ["Widgets", filters],
    queryFn: async () => {
      const httpClient: IHttpClient = new FetchHTTPClient();
      httpClient.setAuthenticationHeader(
        `Bearer ${authenticationData?.accessToken}`
      );

      const url = `${config.ENV_URL_BCK}/v1/Widgets`;

      const response: Record<string, any> = await httpClient.get(url, {
        params: {
          location: filters.location.locationName,
          department: filters.area.areaName,
          area: filters.subarea.subareaName,
        },
      });

      return response;
    },
    enabled: !!authenticationData && !!filters,
  });
}
