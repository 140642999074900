import { Ref, useRef, useState, useLayoutEffect } from "react";

/**
 * hook to measure the width of the component
 * @param {boolean} eagerObserver - if true, the observer will be called immediately
 * if set to false, the observer will be called on the next animation frame, wich may cause a slight delay and flickering
 * if set to true, the observer will be called immediately, which may cause the observer to throw an error "ResizeObserver loop limit exceeded"
 * @returns {array} - [ref, { height, width }]
 */
export function useMeasure(): [Ref<HTMLDivElement>, { height: number; width: number }] {
  const ref = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState({ height: 0, width: 0 });


  useLayoutEffect(() => {
    const ro = new ResizeObserver(([entry, pastEntry]) => {
      return self.requestAnimationFrame(() => {
        return setBounds(entry.contentRect)
      });
    });

    if (ref.current) {
      ro.observe(ref.current);
    }

    return () => ro?.disconnect();
  }, [ref]);

  return [ref, bounds];
}
