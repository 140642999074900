import { useState } from "react";
import { CustomEventType } from "../types/CustomEventType";
import { useCustomEventBusListener, useEventBusLastCall } from "./useEventBus";
import { datadogRum } from '@datadog/browser-rum';

export function useFilters() {
  const filtersBase = useEventBusLastCall(CustomEventType.FILTERS_CHANGED);
  const [filters, setFilters] = useState(filtersBase);

  useCustomEventBusListener(CustomEventType.FILTERS_CHANGED, (event) => {
    const filters: any = event.payload;
    datadogRum.setUserProperty('locationId', filters?.location?.locationId || "")
    datadogRum.setUserProperty('locationName', filters?.location?.locationName || "")
    setFilters(filters);
  });

  return filters;
}
