import "./FallbackStyles.css";

type ParcelErrorFallbackProps = {
  errorMessage: string;
  style?: React.CSSProperties;
  className?: string;
};
export function ParcelErrorFallback({
  errorMessage,
  style,
  className
}: Readonly<ParcelErrorFallbackProps>) {
  const classNames = ["parcel-error-fallback", className].join(" ");
  return (
    <div style={style} className={classNames}>
      {errorMessage}
    </div>
  );
}
