import "./FallbackStyles.css";

type Props = {
  style?: React.CSSProperties;
  className?: string;
};
export function ParcelLoadingFallback({ style, className }: Readonly<Props>) {
  const classNames = ["parcel-loading-fallback", className].join(" ");
  return (
    <div style={style} className={classNames}>
      Loading Application...
    </div>
  );
}
