import { IHttpClient, OptionsType } from "../abstractHttpClient";

export class FetchHTTPClient implements IHttpClient {
  private authenticationHeader: string | null = null;
  setAuthenticationHeader(header: string): void {
    this.authenticationHeader = header;
  }

  async get<T>(url: string, options?: OptionsType): Promise<T> {
    const params = new URLSearchParams();

    if (options?.params) {
      Object.entries(options.params).forEach(
        ([key, value]) => value && params.append(key, value)
      );
    }

    url = `${url}?${params.toString()}`;

    const headers = new Headers({
      ...options?.headers,
      Authorization: this.authenticationHeader,
    });

    const response = await fetch(url, {
      headers,
      signal: options?.abortSignal,
    });

    const parsedResponse = response.json();
    return parsedResponse;
  }

  async post<T>(url: string, data: unknown, options?: OptionsType): Promise<T> {
    const headers = new Headers({
      ...options?.headers,
      Authorization: this.authenticationHeader,
    });

    const response = await fetch(`${url}`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    });

    return response.json();
  }

  async put<T>(url: string, data: unknown, options?: OptionsType): Promise<T> {
    const headers = new Headers({
      ...options?.headers,
      Authorization: this.authenticationHeader,
    });

    const response = await fetch(`${url}`, {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async delete<T>(url: string, options?: OptionsType): Promise<T> {
    const headers = new Headers({
      ...(options?.headers ?? {}),
      Authorization: this.authenticationHeader,
    });

    const response = await fetch(`${url}`, { headers });
    return response.json();
  }

  async patch<T>(
    url: string,
    data: unknown,
    options?: OptionsType
  ): Promise<T> {
    const headers = new Headers({
      ...options?.headers,
      Authorization: this.authenticationHeader,
    });

    const response = await fetch(`${url}`, {
      method: "PATCH",
      headers,
      body: JSON.stringify(data),
    });
    return response.json();
  }
}
