import { Component } from "react";

type Props = {
    children: React.ReactNode;
    fallback: React.JSXElementConstructor<{ errorMessage: string }>;
  };
  
  type State = {
    hasError: boolean;
    errorMessage: string;
  };
  
  export class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = { hasError: false, errorMessage: "" };
    }
  
    static getDerivedStateFromError(error: unknown) {
      return { hasError: true };
    }
  
    componentDidCatch(error: Error, errorInfo: unknown) {
      const errorMessage = error.message || "Unknown Error Ocurred!";
      this.setState({ errorMessage });
    }
  
    render() {
      const ErrorComponent = this.props.fallback;
      const errorMessage = this.state.errorMessage;
      if (this.state.hasError)
        return <ErrorComponent errorMessage={errorMessage} />;
  
      return this.props.children;
    }
  }