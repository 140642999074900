import { SafeParcel } from "../../components/SafeParcel/SafeParcel";
import { Grid, Row } from "../grid-definition";
import { useMemo } from "react";
import { BottomListsWrapper } from "../../components/BottomLists/BottomListsWrapper";

export function AreaGridSmartphone({ location }) {
  const isAllWidget = location.widgets.includes("all");

  const widgetLayout = useMemo(() => {
    if (!location && !location.widgets) return [];

    return isAllWidget
      ? [
          "routine_execution",
          "line_performance",
          "plan_attainment",
          "5why_completion",
          "top_faults",
          "actions_list",
          "individual_routines_list",
          "collaborative_routines_list",
          "fivewhy_list",
        ]
      : location.widgets;
  }, [location]);

  if (!location) return null;

  const shouldRenderWidget = (widgetName) => {
    return widgetLayout.includes(widgetName);
  };

  return (
    <Grid>
      <Row direction="column" spaces="400px 300px 200px 400px">
        {shouldRenderWidget("routine_execution") ? (
          <SafeParcel
            parcelPath="@dws/dws-reports"
            name="dws-routine-execution"
          />
        ) : null}
        {shouldRenderWidget("line_performance") ? (
          <SafeParcel
            parcelPath="@dws/dws-reports"
            name="dws-line-performance"
          />
        ) : null}
        {shouldRenderWidget("plan_attainment") ? (
          <SafeParcel
            parcelPath="@dws/dws-reports"
            name="dws-plan-attainment"
          />
        ) : null}
        {shouldRenderWidget("5why_completion") ? (
          <SafeParcel
            parcelPath="@dws/dws-reports"
            name="dws-fivewhy-doughnut"
          />
        ) : null}
      </Row>
      {shouldRenderWidget("top_faults") ? (
        <Row direction="column">
          <SafeParcel name="dws-top-faults" parcelPath="@dws/dws-reports" />
        </Row>
      ) : null}
      <Row direction="column">
        <BottomListsWrapper widgetLayout={widgetLayout} />
      </Row>
    </Grid>
  );
}
