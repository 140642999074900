import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { Root } from "./root.component";
import "./datadog";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthenticatedSPA } from "@dws/dws-auth";
import { CONSTANTS } from "./constants";

const queryClient = new QueryClient();

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => (
    <QueryClientProvider client={queryClient}>
      <AuthenticatedSPA clientId={CONSTANTS.AUTH.CLIENT_ID}>
        <Root />
      </AuthenticatedSPA>
    </QueryClientProvider>
  ),
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

export { useMeasure } from "./hooks/useMeasure";
