// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-grid {
  padding-block: 2rem;
  display: flex;
  gap: 2rem;
}

.error-message {
  width: 100%;
  text-align: center;
  color: #ffffff;
}

/* less than 1600px */
@media (max-width: 100em) {
  .report-grid {
  }
}

/* less than 900px */
@media (max-width: 50em) {
  .report-grid {
  }
}
`, "",{"version":3,"sources":["webpack://./src/root-component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,cAAc;AAChB;;AAEA,qBAAqB;AACrB;EACE;EACA;AACF;;AAEA,oBAAoB;AACpB;EACE;EACA;AACF","sourcesContent":[".report-grid {\n  padding-block: 2rem;\n  display: flex;\n  gap: 2rem;\n}\n\n.error-message {\n  width: 100%;\n  text-align: center;\n  color: #ffffff;\n}\n\n/* less than 1600px */\n@media (max-width: 100em) {\n  .report-grid {\n  }\n}\n\n/* less than 900px */\n@media (max-width: 50em) {\n  .report-grid {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
