import React from "react";
import "./grid-definition.css";

type GridProps = {
  children: React.ReactElement | Array<React.ReactElement>;
};
export function Grid({ children }: Readonly<GridProps>) {
  return <div className="dws-grid">{children}</div>;
}

type RowProps = {
  children: React.ReactElement | Array<React.ReactElement>;
  direction: "column" | "row";
  spaces?: string;
};
export function Row({ children, direction, spaces }: Readonly<RowProps>) {
  const [mainKey, secondaryKey] =
    direction == "column"
      ? ["gridTemplateRows", "gridTemplateColumns"]
      : ["gridTemplateColumns", "gridTemplateRows"];
  return (
    <div
      style={{
        flexDirection: direction,
        [mainKey]: spaces,
        [secondaryKey]: "1fr",
        position: "relative",
      }}
      className="dws-row"
    >
      {children}
    </div>
  );
}
