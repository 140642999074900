export enum CustomEventType {
    BUTTON_CLICKED = "BUTTON_CLICKED",
    CHART_DATA = "CHART_DATA",
    NAVIGATE = "NAVIGATE",
    COUNT_CHANGED = "COUNT_CHANGED",
    LOAD = "LOAD",
    ERROR = "ERROR",
    REQUEST_REFRESH = "REQUEST_REFRESH",
    FILTERS_CHANGED = "FILTERS_CHANGED",
  }
  