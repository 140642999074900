import { useEffect, useState } from "react";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import { ParcelErrorFallback } from "./ParcelErrorFalback";
import { ParcelLoadingFallback } from "./ParcelLoadingFallback";
import Parcel from "single-spa-react/parcel";

type Props = {
  parcelPath: string;
  name: string;
};
export function SafeParcel({ name, parcelPath }: Readonly<Props>) {
  return (
    <ErrorBoundary fallback={ParcelErrorFallback}>
      <LazyApplication parcelPath={parcelPath} name={name} />
    </ErrorBoundary>
  );
}

function LazyApplication({ parcelPath, name }: Readonly<Props>) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [app, setApp] = useState<System.Module>(null);

  useEffect(() => {
    setLoading(true);
    System.import(parcelPath)
      .then((res) => {
        setLoading(false);
        setApp(res);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        console.error(err);
      });
  }, []);

  if (error)
    return (
      <ParcelErrorFallback
        className={name}
        errorMessage="An Error ocurred while trying to load the application"
      />
    );
    
  if (loading || !app) return <ParcelLoadingFallback className={name} />;

  return <Parcel config={{ ...app.default, name }} wrapClassName={name} />;
}
